@import '../../styles/propertySets.css';

.root {
  padding: 0 24px;
}

.field {
  & input {
    font-size: 18px;
    padding: 10px 0;
  }
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 24px;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadowTop);
}
