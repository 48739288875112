.logoMobile {
  height: 28px;
  width: 28px;
}

.logoMobileIcon {
  & path {
    fill: var(--marketplaceColor);
  }
}
