.root {
  display: flex;
  flex-direction: column;
}

.listingCards {
  padding: 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.listingCard {
  margin-bottom: 36px;
  flex-basis: 100%;

  @media (--viewportMedium) {
    flex-basis: calc(33.33% - 16px);
    margin-right: 24px;
  }

  @media (--viewportLarge) {
    flex-basis: calc(25% - 18px);
    margin-right: 24px;
  }
}

/**
 * Remove margin-right from listingCards on the last column
 * This is a special case in the world of breakpoints. Nth-of-type effects every nth item.
 * Here we take margin-right away, but we don't want it affect the next column breakpoint.
 */

.listingCard:nth-of-type(3n) {
  @media (--viewportMedium) {
    margin-right: 0;
  }

  @media (--viewportLarge) {
    margin-right: 24px;
  }
}

.listingCard:nth-of-type(4n) {
  @media (--viewportLarge) {
    margin-right: 0;
  }
}

.pagination {
  /* margin-top: auto; pushes pagination to the end of the page. */
  margin-top: auto;
  padding: 0 24px;

  @media (--viewportLarge) {
    padding: 0;
  }
}
