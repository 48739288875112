@import '../../styles/propertySets.css';

.root {
}

.accordion {
  margin-bottom: 12px;
}

.singleAccordion {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.8em 1em;

  /* Style */
  background-color: grey;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: var(--marketplaceColor);
  }

  & h2 {
    /* Font */
    @apply --marketplaceH2FontStyles;
    font-weight: var(--fontWeightMedium);
    color: var(--matterColorLight);

    /* Layout */
    margin: 0;
  }
}

.activeAccordion {
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  & svg {
    transform: rotate(90deg);
  }
}

.accordionContent {
  /* Style */
  background: rgb(169, 84, 9, 0.1);
  border-bottom: 2px solid var(--marketplaceColor);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 14px 24px;

  /* Content headings */
  & h2 {
    margin-top: 17px;
    margin-bottom: 17px;

    @media (--viewportMedium) {
      margin-top: 19px;
      margin-bottom: 19px;
    }
  }
}

.arrow {
  fill: var(--matterColorLight);
  stroke: var(--matterColorLight);

  /* Animation */
  transition: all 0.25s ease-in-out;
}
