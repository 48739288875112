@import '../../styles/propertySets.css';

.root {
}

.content {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}

.avatar {
  flex-shrink: 0;
  margin-right: 24px;
}

.info {
  flex: 1;
}

.heading {
  @apply --marketplaceH2FontStyles;
  line-height: inherit;
  margin: 0;
}

.link {
  white-space: nowrap;
  text-decoration: underline;
  font-size: 13px;
}

.mobileBio,
.desktopBio {
  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;
  margin: 18px 0 0 0;
  transition: all 1s ease-in;
  @apply --marketplaceH4FontStyles;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.mobileBio {
  @media (--viewportMedium) {
    display: none;
  }
}

.desktopBio {
  display: none;

  @media (--viewportMedium) {
    display: block;
  }
}

.showMore {
  @apply --marketplaceLinkStyles;
  @apply --marketplaceH4FontStyles;
  margin: 0 0 0 5px;
}

.links {
  display: flex;
  align-items: center;

  @apply --marketplaceSmallFontStyles;
  margin-top: 10px;
  margin-left: calc(60px + 24px);
}

.withBioMissingAbove {
}

.linkSeparator {
  margin: 0 8px;
  color: var(--marketplaceColor);
}

.headingRow {
  display: flex;
  flex-direction: column;
  line-height: 1.2;

  @media (--viewportMedium) {
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
  }
}

.editProfileDesktop {
  @apply --marketplaceH4FontStyles;
  display: none;
  margin-bottom: 0;
  white-space: nowrap;

  @media (--viewportMedium) {
    display: inline;
  }
}

.editProfileMobile {
  @apply --marketplaceH4FontStyles;
  display: inline;

  @media (--viewportMedium) {
    display: none;
  }
}

.contact {
  @apply --marketplaceLinkStyles;
  @apply --marketplaceH4FontStyles;
  font-weight: 400;
  text-decoration: underline;
  margin: 0;
}

.averageReview {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.averageReviewText {
  /* Font */
  @apply --marketplaceSmallFontStyles;
  font-size: 13px;
  line-height: 1;

  /* Layout */
  position: relative;
  top: 1px;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 8px;
}

.reviewIcon {
  height: 14px;
  width: 14px;
}

.numberOfReviewsText {
  @apply --marketplaceSmallFontStyles;
  color: var(--matterColorDark);
  font-size: 13px;
  margin: 7px 0 0;
}
