@import '../../../styles/propertySets.css';

.root {
  @apply --marketplaceModalBaseStyles;
  padding-top: 70px;
  margin-top: 35.5vh !important;
  @media (--viewportMedium) {
    flex-basis: 567px;
  }
}

.modalContent {
  flex-grow: 1;
}

/* Icon of the modal */
.modalIcon {
  @apply --marketplaceModalIconStyles;
}

/* Title of the modal */
.modalTitle {
  @apply --marketplaceModalTitleStyles;
  font-size: 20px;
}

.reviewee {
  white-space: nowrap;
}

/* Paragraph for the Modal */
.modalMessage {
  @apply --marketplaceModalParagraphStyles;
}

.buttons {
  display: flex;
  gap: 12px;
}
