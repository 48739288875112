@import '../../styles/propertySets.css';

.root {
  /* Layout */
  position: fixed;
  align-items: center;
  justify-content: center;

  /* Position */
  bottom: 48px;
  right: 48px;
  z-index: 8;

  /* Size */
  height: 48px;
  width: 48px;

  /* Style */
  background-color: var(--marketplaceColor);
  border-radius: 50%;
  cursor: pointer;

  /* Animation */
  animation: fadeIn 0.4s;
  transition: opacity 0.4s;
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }
}

.arrow {
  stroke: var(--matterColorLight);
  fill: var(--matterColorLight);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}
