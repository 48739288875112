@import '../../styles/propertySets.css';

/* Desktop */
.root {
  position: relative;

  /* Size */
  width: 100%;
  height: var(--topbarHeightDesktop);

  /* fill */
  background-color: var(--matterColorLight);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  /* shadows
  box-shadow: var(--boxShadowMedium); */

  /* Layout for child components */
  display: flex;
  align-items: center;
}

.main {
  @apply --marketplacePageWidth;

  /* Size */
  height: 100%;
  width: 100%;

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
}

/* logo */
.logoLink {
  @media (--viewportLarge) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.logo {
  /* Position */
  position: relative;
  top: 1px;

  /* If height is increased, change link paddings accordingly */
  height: 40px;

  /* Good aspect ratio should be less than 160/27 (width/height) */
  /* NOTE: extremely wide logos, need special handling. */
  width: auto;
  max-width: 220px;
  object-fit: contain;
  object-position: left center;
}

/* Create listing (CTA for providers) */
.createListingLink {
  text-decoration: none !important;
}

.createListing {
  @apply --marketplaceButtonStyles;

  /* Default styles */
  padding: 10px 17px;
  min-height: 42px;

  /* Default font styles */
  line-height: 1;
}

/* Profile menu */
.profileMenuLabel {
  @apply --TopbarDesktop_linkHover;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0;
  padding: 0;
  outline: 0;

  &:active {
    border-bottom: 0;
  }
}

.profileMenuIsOpen {
  & .topbarIcon {
    fill: var(--marketplaceColor);
  }

  &:hover {
    border-bottom: 0;
  }
}

.profileMenuContent {
  min-width: 276px;
  padding-top: 20px;
}

.topbarLink {
  /* Font */
  @apply --TopbarDesktop_linkHover;
  @apply --marketplaceH5FontStyles;
  color: var(--matterColorDark);

  /* Layout */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  /* Style and size */
  height: 100%;
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 24px;
  margin-left: 0;

  &:hover {
    /* On hover change icon color */
    & .topbarIcon {
      fill: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.topbarIcon {
  height: 22px;
  width: 22px;
  margin-bottom: 7px;
  transition: all var(--transitionStyleButton);
}

.inboxNotificationIcon {
  fill: var(--marketplaceColor);
}

/* left animated "border" like hover element */
.menuItemBorder {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 4px);
  width: 0;
  transition: width var(--transitionStyleButton);
}

.profileSettingsLink,
.yourListingsLink {
  @apply --marketplaceH4FontStyles;
  position: relative;
  display: block;

  /* Dimensions */
  width: 100%;
  margin: 0;
  padding: 4px 24px;

  /* Layout details */
  color: var(--matterColor);
  text-align: left;
  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--matterColorDark);
    text-decoration: none;

    & .menuItemBorder {
      width: 6px;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.logoutButton {
  @apply --marketplaceLinkStyles;
  /* Font is specific to this component */
  @apply --marketplaceH5FontStyles;
  font-size: 14px;

  /* Dimensions */
  position: relative;
  width: 100%;
  min-width: 276px;
  margin: 0;
  padding: 20px 24px;

  /* Layout details */
  color: var(--matterColorAnti);
  text-align: left;
  white-space: nowrap;
  transition: var(--transitionStyleButton);
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: var(--matterColorNegative);

  &:hover {
    color: var(--matterColorDark);
    text-decoration: none;

    & .menuItemBorder {
      height: 100%;
      width: 6px;
      top: 0;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    margin-top: 20px;
    margin-bottom: 0;
  }
}

.currentPage {
  color: var(--matterColorDark);
  position: relative;

  & .menuItemBorder {
    width: 6px;
    background-color: var(--matterColorDark);
  }

  &:hover {
    & .menuItemBorder {
      background-color: var(--matterColorDark);
    }
  }
}

.links {
  display: flex;
  align-items: center;
  height: 100%;
}

.actions {
  display: none;

  @media (--viewportLarge) {
    display: flex;
    align-items: center;
  }
}

.socials {
  display: flex;
  align-items: center;
  margin-left: 12px;
}

.icon {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: center;

  /* Style */
  background-color: var(--marketplaceColor);
  border-radius: 50%;
  transition: all var(--transitionStyleButton);

  /* Size */
  width: 34px;
  height: 34px;

  &:hover {
    background-color: var(--marketplaceColorDark);
    box-shadow: var(--boxShadowButton);
  }
}

.fbIcon {
  margin-right: 12px;
}

.svgIcon {
  fill: var(--matterColorLight);

  &:hover {
    fill: var(--matterColorLight);
  }
}

.searchContainer {
  /* Layout */
  display: flex;
  align-items: center;
  height: calc(100% - 24px);

  /* Style */
  padding-right: 24px;
  margin-right: 24px;

  /* Right border */
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: rgba(0, 0, 0, 0.1);

  & svg {
    height: 22px;
    width: 22px;
    cursor: pointer;

    & g {
      stroke: var(--matterColorDark);
      stroke-width: 1.5px;
      transition: all var(--transitionStyleButton);
    }

    &:hover {
      & g {
        stroke: var(--marketplaceColor);
      }
    }
  }
}
