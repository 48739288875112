@import '../../styles/propertySets.css';

.root {
  /* Size */
  width: 100%;
  height: var(--topbarHeightDesktop);

  /* fill */
  background-color: var(--matterColorLight);
  transition: box-shadow var(--transitionStyleButton);

  display: none;

  @media (--viewportLarge) {
    /* Layout for child components */
    display: flex;
    align-items: center;
  }
}

.scrollClass {
  /* shadows */
  box-shadow: var(--boxShadowMedium);
}

.main {
  @apply --marketplacePageWidth;

  /* Size */
  height: 100%;
  width: 100%;

  /* Layout for child components */
  display: flex;
  align-items: center;
  padding: 0 24px;
}

.menuList {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  margin: 0;
}
