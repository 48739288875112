@import '../../styles/propertySets.css';

.root {
  @media (--viewportMedium) {
    padding-bottom: 24px;
  }
}

.sectionContent {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 48px;

  /* Font markup */
  text-align: center;

  @media (--viewportMedium) {
    grid-template-columns: 1fr 1fr;
  }
}

.title {
  @apply --marketplaceH1FontStyles;
  color: var(--matterColorDark);

  margin-top: 0;
  margin-bottom: 0;
}

.message {
  color: var(--matterColorDark);
  max-width: 365px;
  margin: 12px auto 34px;
  line-height: 24px;
}

.image {
  position: relative;
  overflow: hidden;
  height: 225px;
  border-radius: 3px;

  @media (--viewportMedium) {
    height: 390px;
  }

  & > div {
    &:nth-child(3) {
      inset: -2px !important;
    }
  }

  /* On hover activate the overlay */
  &:hover {
    & .overlay {
      opacity: 1;
    }
  }
}

.img {
  height: 100%;
  border-radius: 3px;
}

.magazineImg {
  width: 100%;
  object-fit: contain;
}

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.2);
  cursor: pointer;
  z-index: 2;

  /* Animation */
  transition: opacity 0.3s ease-in-out;
}

.shadow {
  position: absolute;
  bottom: -70px;
  left: 0;
  display: block;
  width: 100%;
  height: 0;
  box-shadow: 0 0 149px 90px rgb(0 0 0 / 70%);
  z-index: 1;

  /* Animation */
  transform: rotate(-24deg);
}

.border {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border: 4px solid var(--marketplaceColor);

  &:after {
    content: '';
    height: 70%;
    width: 4px;
    position: absolute;
    right: -4px;
    top: 50%;
    background: var(--matterColorLight);
    transform: translate(0, -50%);
  }

  &:before {
    content: '';
    height: 70%;
    width: 4px;
    position: absolute;
    left: 0;
    top: 50%;
    background: var(--matterColorLight);
    transform: translate(-4px, -50%);
  }
}
