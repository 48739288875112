@import '../../styles/customMediaQueries.css';
@import '../../styles/marketplaceDefaults.css';

.root {
  display: flex;
  flex-direction: column;

  /* Layout: size and positioning */
  width: 100%;
  height: auto;
  margin-top: 14px;
}

.error {
  color: var(--failColor);
}

.errorPlaceholder {
  @media (--viewportMedium) {
    width: 100%;
    height: 32px;
  }
}

.reviewRating {
  margin-bottom: 18px;

  @media (--viewportMedium) {
    margin-bottom: 16px;
  }
}

.reviewContent {
  flex-shrink: 0;
  margin-top: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-top: 30px;
  }
}

.submitButton {
  margin-top: auto;
  margin-bottom: 96px;
  flex-shrink: 0;

  @media (--viewportMedium) {
    display: inline-block;
    width: 100%;
    margin-top: 36px;
    margin-bottom: 0;
  }
}

.cardCarrier {
  display: flex;
  align-items: center;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 10px 30px;
  justify-content: space-between;
  border: 1px solid #0000;
  cursor: pointer;

  @media (max-width: 600px) {
    flex-wrap: wrap;
    gap: 20px;
    padding: 10px;
  }

  & .providerImage {
    & img {
      width: 100px;
    }
  }
}

.selectedCard {
  border: 1px solid var(--marketplaceColorDark);
  background: var(--matterColorBright);
}

.carrierHeading {
  font-size: 14px;
  color: var(--matterColorDark);
  margin-bottom: 16px;
  font-weight: 600;
}

.cardCarrierData {
  flex-direction: column;
  display: flex;

  & .providerName {
    font-weight: 700;
    font-size: 18px;
  }
}

.cardBox {
  display: flex;
  align-items: center;
  gap: 30px;

  @media (max-width: 768px) {
    justify-content: space-between;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }
}

.priceBox {
  display: flex;
  align-items: flex-end;
  flex-direction: column;

  @media (max-width: 768px) {
    gap: 10px;
    align-items: flex-start;
  }
}

.rightBox {
  display: flex;
  align-items: center;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: row;
    align-items: flex-end;
  }
}

.badgeBox {
  & span {
    background-color: var(--marketplaceColorLight);
    color: var(--matterColorLight);
    margin-left: 10px;
    border-radius: 3px;
    padding: 5px 10px;
    font-size: 11px;
    position: relative;
    bottom: 3px;

    @media (max-width: 768px) {
      margin-right: 10px;
      margin-left: unset;
    }
  }
}

.selectIcon {
  cursor: pointer;
}

.estDeliveryText {
  color: var(--matterColorAnti);
  font-size: 12px;
  line-height: 15px;
}

.priceText {
  font-weight: 600;
  color: var(--marketplaceColor);
}

.title {
  display: flex;
}

.textInputs {
  @media (min-width: 1024px) {
    display: flex;
    justify-content: space-between;
  }
}
