@import '../../styles/propertySets.css';

/* Title of the modal */
.modalTitle {
  @apply --marketplaceModalTitleStyles;
}

/* Paragraph for the Modal */
.modalMessage {
  @apply --marketplaceModalParagraphStyles;
  margin: 12px 0 24px;
}

.submitButton {
  margin-top: 72px;
}

.priceInfo {
  margin: 12px 0 0;
  font-weight: var(--fontWeightMedium);
}

.negotiatedTotal {
  margin: 0;
  color: var(--marketplaceColor);
}
