@import '../../../styles/propertySets.css';

.root {
  @apply --marketplaceModalBaseStyles;
  padding-top: 70px;

  @media (--viewportMedium) {
    flex-basis: 567px;
  }
}

.modalContent {
  flex-grow: 1;
  & > div {
    padding: 14px;
  }
}

/* Icon of the modal */
.modalIcon {
  @apply --marketplaceModalIconStyles;
}

/* Title of the modal */
.modalTitle {
  @apply --marketplaceModalTitleStyles;
  font-size: 20px;
}

.reviewee {
  white-space: nowrap;
}

/* Paragraph for the Modal */
.modalMessage {
  @apply --marketplaceModalParagraphStyles;
}

.info {
  display: flex;

  & > img {
    margin: 0px 2px 18px 0px;
    width: 170px;
    height: 220px;
  }
}

.details {
  margin-left: 20px;

  & > div {
    font-size: 14px;
    font-weight: 600;
    color: var(--matterColor);
    line-height: 18px;
  }
}

.map {
  & > iframe {
    width: 409px;
    height: 250px;
  }
}

.heading {
  font-size: 12px;
  font-weight: 500 !important;
  color: var(--matterColorAnti) !important;
  line-height: 16px;
  margin-bottom: 12px;
}
