@import '../../styles/propertySets.css';

.root {
}

.sectionContent {
  text-align: center;
}

.title {
  @apply --marketplaceH1FontStyles;

  margin-top: 0;
  margin-bottom: 0;
}

.message {
  color: var(--matterColorDark);
  max-width: 365px;
  margin: 16px auto 48px;
  line-height: 24px;
}

.buttonContainer {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: center;

  /* Position */
  width: 100%;
  margin-top: 48px;
}

.actionButton {
  @apply --marketplaceButtonStyles;

  @media (--viewportMedium) {
    width: 260px;
  }
}
