@import '../../styles/propertySets.css';

.root {
  position: relative;
  padding: 0;
  width: 100%;
}

.filterLabel,
.filterLabelSelected {
  @apply --marketplaceH3FontStyles;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;

  /* Baseline adjustment for label text */
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
}

.filterLabel {
  color: var(--matterColorDark);
}

.filterLabelSelected {
  color: var(--marketplaceColor);
}

.labelButton {
  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;
  padding: 0;
  cursor: pointer;
}

.clearButton {
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorAnti);

  /* Layout */
  display: inline;
  float: right;
  margin-right: 0;
  margin-left: 48px;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;

  &:focus,
  &:hover {
    color: var(--matterColor);
  }
}

.plain {
  width: 100%;
  display: none;
}

.isOpen {
  display: block;
}
