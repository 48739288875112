@import '../../styles/propertySets.css';

.root {
  position: relative;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: var(--matterColorNegative);

  width: 100%;
  background-color: var(--marketplaceColorLight);
}

.content {
  /* Layout */
  @apply --marketplacePageWidth;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 48px 24px 80px;

  @media (--viewportMedium) {
    padding: 70px 24px 80px;
  }
}

/* Mobile only social media links */
.someLiksMobile {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    display: none;
  }
}

.icon {
  display: inline-block;
  margin-right: 12px;
  text-align: center;
}

.icon svg {
  width: 22px;
  height: 22px;
}

.icon svg path {
  fill: var(--matterColorAnti);
  transition: fill 300ms ease 0s;
}

.icon:hover svg path {
  fill: var(--marketplaceColor);
}

/* Footer links */
.links {
  /* Layout */
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;

  @media (--viewportMedium) {
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-bottom: 0;

    & > div {
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.social {
  margin-left: -3px;
}

/* Desktop: logo and organization stuff */
.organization {
  flex-shrink: 0;
  flex-basis: 220px;
  display: none;
  margin-right: 24px;

  @media (--viewportMedium) {
    display: block;
    order: 1;
    margin-right: 12px;
  }
  @media (--viewportLarge) {
    flex-basis: 230px;
    margin-right: 57px;
  }
  @media (--viewportLargeWithPaddings) {
    flex-basis: 240px;
    margin-right: 12px;
  }
}

.logoLink {
  display: flex;
  margin: 0;
  text-decoration: none !important;
}

.logo {
  position: relative;
  height: 35px;
  width: auto;
  object-fit: contain;
  object-position: left center;
}

.organizationInfo {
}

.organizationDescription {
  font-size: 13px;
  line-height: 17px;
  margin: 24px 0;

  @media (--viewportMedium) {
    margin-right: 10px;
    margin-top: 23px;
    margin-bottom: 23px;
  }
}

.organizationCopyright {
  color: var(--matterColorDark);
  font-size: 13px;
  line-height: 20px;
  margin: 24px 0 0;
}

.copyrightLink {
  color: var(--matterColorDark);
  transition: var(--transitionStyleButton);

  &:hover {
    text-decoration: none;
  }
}

/* Desktop and Mobile: links to more information */
.infoLinks {
  order: 2;
  flex-basis: 175px;
  margin-right: 24px;

  @media (--viewportMedium) {
    padding-top: 2px;
    margin-right: 12px;
  }
}

.list {
  margin-top: 0;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.listItem {
  min-height: 30px;

  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.listTitle {
  font-weight: 600;
  font-size: 16px;
  color: var(--matterColorDark);
  min-height: 30px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 0;
}

.link {
  display: flex;
  align-items: center;

  /* Font */
  @apply --marketplaceH4FontStyles;
  color: var(--matterColorDark);
  line-height: 20px;
  transition: var(--transitionStyleButton);

  margin-top: 0;
  margin-bottom: 12px;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;

    & svg {
      fill: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }

  & svg {
    margin-right: 7px;
    fill: var(--matterColorDark);
  }
}

/* Desktop and Mobile: searches - first column */
.searches {
  order: 3;
  flex-basis: 165px;
  margin-right: 24px;

  @media (--viewportMedium) {
    padding-top: 2px;
    margin-right: 12px;
  }
}

/* Desktop and Mobile: searches - second column */
/* This is not always visible */
.searchesExtra {
  order: 3;
  flex-basis: 165px;
  margin-right: 24px;

  @media (--viewportMedium) {
    padding-top: 2px;
    margin-right: 12px;
  }
}

/* Desktop: extra links (social media links and legal links) */
.extraLinks {
  flex-shrink: 0;
  order: 5;
  display: none;
  flex-basis: 109px;
  margin: 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
  }
  @media (--viewportLarge) {
    margin-right: 0;
  }
}

.legalMatters {
  /* Get extra space from parent element (which is using flexbox) */
  flex-grow: 1;

  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 12px;
}

.tosAndPrivacy {
  margin-top: 0;
  margin-bottom: 0;
}

.legalLink,
.privacy,
.terms {
  /* Font */
  font-size: 13px;
  line-height: 13px;
  color: var(--matterColorAnti);
  transition: var(--transitionStyleButton);

  margin-top: 0;
  margin-bottom: 0;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

/* Mobile: copyright mark and legal links for mobile */
.copyrightAndTermsMobile {
  /* Positioning */
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  /* Dimensions */
  padding: 22px 24px;

  /* Layout */
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: var(--matterColorNegative);
  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    display: none;
  }
}

.organizationCopyrightMobile {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorDark);
  transition: var(--transitionStyleButton);

  /* Tell parent component how much space this one prefers */
  flex-basis: 100%;

  margin-top: 0;
  margin-bottom: 0;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }
}

.tosAndPrivacyMobile {
  display: none;
  flex-basis: 172px;
  text-align: right;
}

.privacy {
  margin-right: 24px;
}

.socialItem {
  display: none;

  @media (--viewportMedium) {
    display: block;
  }
}
