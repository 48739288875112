@import '../../styles/propertySets.css';

.root {
}

.menuLabel {
  padding: 0;

  /* Effect */
  cursor: pointer;
  transition: var(--transitionStyleButton);

  /* Pop on hover */
  &:hover {
    transform: scale(1.1);
  }
}

.menuContent {
  display: flex;
  flex-direction: column;

  /* Style */
  padding: 12px 0;
  border-radius: 8px;

  @media (max-width: 767px) {
    max-width: 200px;
    margin-top: 12px;

    /* Position */
    left: unset !important;
    right: 0;

    & > div:first-child {
      display: none;
    }
  }
}

.menuItem {
  padding: 10px 20px;

  /* Effect */
  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--matterColorLocalizationHover);
  }
}

.menuItemActive {
  background-color: var(--matterColorLocalizationActive);
}

.menuItemButton {
  display: flex;
  align-items: flex-start;
}

.currentLocalizationFlag,
.localizationFlag {
  height: 24px;
}

.localizationFlag {
  margin-top: 2px;
  margin-right: 12px;
}

.localizationInfo {
  /* Layout */
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  white-space: nowrap;

  /* Font */
  @apply --marketplaceLocalizationFontStyles;
}

.localizationCountryText {
  @apply --marketplaceLocalizationSmallFontStyles;
}
