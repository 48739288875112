@import '../../styles/propertySets.css';

.menuItem {
  /* Font */
  @apply --marketplaceH3FontStyles;
  text-transform: uppercase;
  color: var(--matterColorDark);
  white-space: nowrap;

  /* Layout */
  position: relative;
  display: inline;
  margin: 0;
  flex: 1;
  height: 100%;
  width: 100%;
  text-align: center;

  /* Animation */
  transition: all var(--transitionStyleButton);

  &:hover {
    color: var(--marketplaceColor);
  }
}

.menuItemLabel {
  margin: 24px 0;
}

.activeMenuItem {
  position: relative;
  color: var(--marketplaceColor);

  &:after {
    content: '';

    /* Position */
    position: absolute;
    bottom: 0;

    /* Size */
    width: 0;
    height: 0;

    /* Triangle up */
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid rgba(0, 0, 0, 0.1);
  }
}

.menuItemLink {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  /* Font */
  color: var(--matterColorDark);

  /* Animation */
  transition: all var(--transitionStyleButton);

  &:hover {
    color: var(--marketplaceColor);
  }
}

.dropdown {
  /* Grid */
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 72px;

  /* Position */
  position: absolute;
  right: -1px;

  /* Layout */
  padding: 40px;
  z-index: 9;

  /* Style */
  background: var(--matterColorLight);
  box-shadow: var(--boxShadowPopup);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

.dropdownWithoutImage {
  grid-template-columns: auto;
  grid-gap: 0;
}

.dropdownReversed {
  grid-gap: 48px;
}

.dropdownLeft {
  right: initial;
  left: 0;
}

.dropdownWrapper {
  /* Layout */
  display: flex;
  align-items: flex-start;
}

.dropdownWrapperReversed {
  flex-direction: column;
}

.imageContainer {
  display: flex;
  align-items: center;
}

.imageContainerReversed {
  grid-column-start: 1;
  grid-row-start: 1;
}
