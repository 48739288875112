@import '../../styles/propertySets.css';

.root {
  /* Font */
  position: relative;
  display: flex;
  align-items: center;
}

.rootIsOpen {
  & .icon {
    & g {
      stroke: var(--marketplaceColor);
    }
  }
}

.field {
  /* Position */
  position: absolute;
  top: 0;
  right: 0;

  /* Hide the input by default */
  visibility: hidden;
  pointer-events: none;
  opacity: 0;

  & input {
    /* Layout */
    width: 0;
    padding: 5px 28px 5px 0;

    /* Font */
    font-size: 14px;

    /* Animation */
    transition: width 0.6s cubic-bezier(0, 0.795, 0, 1);
  }
}

.fieldIsOpen {
  /* Show the input */
  visibility: visible;
  pointer-events: all;
  opacity: 1;

  & input {
    width: 197px;
  }
}

.icon {
  /* Layout */
  position: relative;
  z-index: 1;
}
