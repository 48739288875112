@import '../../styles/propertySets.css';

.root {
  @apply --marketplaceModalBaseStyles;
  padding-top: 70px;

  @media (--viewportMedium) {
    flex-basis: 567px;
  }
}

.modalContent {
  flex-grow: 1;
}

/* Paragraph for the Modal */
.modalMessage {
  @apply --marketplaceModalParagraphStyles;
  margin-top: 12px;
}

.imageContainer {
  display: flex;
  justify-content: center;
  border-bottom: 2px solid var(--matterColorDark);
}

.foxImage {
  height: 130px;
}
