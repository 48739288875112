@import '../../styles/propertySets.css';

:root {
  --arrowHoverOffset: translateX(3px);
}

/* Animation for the hero elements */

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }
  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

/* Shared animation rules */
.animation {
  animation-name: animationTitle;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;

  visibility: hidden;
  opacity: 1;
  position: relative;
}

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  /* Text positioning is done with paddings */
}

.heroMain {
  @media (--viewportMedium) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 12px;
  }
}

.heroContent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.heroMainTitle {
  @apply --marketplaceHeroTitleFontStyles;
  font-family: var(--fontFamily);
  color: var(--matterColorDark);

  composes: animation;
  animation-delay: 0.5s;

  @media (--viewportMedium) {
    max-width: var(--SectionHero_desktopTitleMaxWidth);
  }
}

.heroSubTitle {
  @apply --marketplaceH4FontStyles;
  font-family: var(--fontFamily);
  color: var(--matterColorDark);
  margin: 16px 0 24px;

  composes: animation;
  animation-delay: 0.65s;

  @media (--viewportMedium) {
    max-width: var(--SectionHero_desktopSubTitleMaxWidth);
  }
}

.introImage {
  /* Layout */
  height: 210px;
  margin-bottom: 17px;
  margin-left: -5px;

  /* Style */
  border-bottom-left-radius: 10%;
  border-bottom-right-radius: 10%;

  /* Animation */
  composes: animation;
  animation-delay: 0.35s;
}

.heroButton {
  @apply --marketplaceButtonStyles;
  composes: animation;

  animation-delay: 0.8s;

  @media (--viewportMedium) {
    display: block;
    width: 260px;
  }
}

.bottomLink {
  display: flex;
  align-items: center;
  margin-top: 24px;

  composes: animation;
  animation-delay: 0.95s;

  &:hover {
    & svg {
      & g {
        & path {
          &:nth-child(1) {
            opacity: 1;
          }

          &:nth-child(2) {
            transform: var(--arrowHoverOffset);
          }
        }
      }
    }
  }
}
