@import '../../styles/propertySets.css';

.root {
  /* Layout */
  position: relative;
}

.listingCardLink {
  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.aspectWrapper {
  position: relative;
  overflow: hidden;

  /* Portrait */
  padding-bottom: 140%;

  /* Default style */
  background: var(--matterColorNegative);

  /* Disable copy selection */
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.image {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  border-radius: 2px;
  transition: all 0.35s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }
}

.info {
  display: flex;
  flex-direction: column;
  margin-top: 18px;
}

.heading {
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorDark);

  /* Position */
  margin: 0;

  /* Limit two lines */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  @media (--viewportMedium) {
    min-height: 48px;
  }
}

.price {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  max-width: 260px;
  margin: 18px 0 0;

  /* Style */
  position: relative;
  background-color: var(--matterColorLight);
  border-width: 1px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.1);
  border-radius: 2px;

  /* Font */
  @apply --marketplaceSmallFontStyles;
  color: var(--matterColorDark);
  text-align: center;

  /* Animation */
  transition: all var(--transitionStyleButton);

  &:hover {
    background-color: var(--marketplaceColor);
    border-color: var(--marketplaceColor);
    color: var(--matterColorLight);
  }
}

.shadow {
  /* Remove shadow */
  display: none;

  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  box-shadow: 0 0 149px 90px rgb(0 0 0 / 70%);
  z-index: 1;
  transform: rotate(-24deg);
}

.soldOutBanner {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: center;

  /* Positon */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  /* Size */
  padding: 15px;
  width: 100%;

  /* Styles */
  background: rgba(0, 0, 0, 0.7);

  /* Font */
  text-align: center;
}

.soldOutHeading {
  /* Font */
  font-family: var(--fontFamilySecondary);
  font-size: 22px;
  line-height: 1;
  color: var(--matterColorLight);

  margin: 0;
}
