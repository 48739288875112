@import '../../styles/customMediaQueries.css';
@import '../../styles/propertySets.css';

.pageTitle {
  margin: 0 0 16px;
}

.staticPageWrapper {
  @apply --marketplacePageWidth;
  padding: 48px 24px 72px;
}

.coverFigure {
  margin: 0;
}

.coverCaption {
  @apply --marketplaceSmallFontStyles;
  text-align: center;
}

.coverImage {
  width: 100%;
  height: 400px;
  border-radius: 4px;
  object-fit: cover;
  margin: 0 0 18px;
}

.contentWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media (--viewportMedium) {
    flex-wrap: nowrap;
  }
}

.contentSide {
  width: 100%;

  @media (--viewportMedium) {
    width: 193px;
    margin-right: 103px;
  }
}

.navigationLinks {
  margin: 0 0 24px;

  @media (--viewportMedium) {
    margin: 6px 0 0;
  }
}

.navigationLink {
  @apply --marketplaceSmallFontStyles;
  position: relative;
  border-radius: 4px;
  margin-bottom: 6px;

  & a {
    display: flex;
    align-items: center;
    color: var(--matterColorDark);
    padding: 8px 12px;
    width: 100%;

    &:hover {
      color: var(--marketplaceColor);
    }
  }
}

.active {
  background: #e8e8e8;
  font-weight: bold;

  & a,
  &:after {
    color: var(--marketplaceColor);
  }

  &:after {
    position: absolute;
    right: 0.75em;
    top: 0.4375em;
    pointer-events: none;
    content: '→';
  }
}

.contentMain {
  @media (--viewportMedium) {
    width: 650px;
  }
}

.contentTitle {
  margin-top: 0;
}

.subtitle {
  margin: 0;
}

.signImage {
  margin-top: 24px;
  width: 100%;
}

.meetImage {
  width: 100%;
}

.icon {
  margin-right: 8px;
}

.returnLinkWrapper {
  margin: 0 0 24px;
  line-height: 1;
}

.returnLink {
  @apply --marketplaceSmallFontStyles;
}

.arrowIcon {
  height: 10px;
  width: 10px;
  margin-right: 6px;

  /* fill */

  fill: var(--marketplaceColor);
  stroke: var(--marketplaceColor);
}
