@import '../../styles/propertySets.css';

.root {
  /* Layout */
  position: relative;
  padding-right: 72px;

  /* Font */
  text-align: left;

  &:last-child {
    padding-right: 0;
  }
}

.reverseRoot {
  padding-right: 0;
  padding-bottom: 24px;

  &:last-child {
    padding-bottom: 0;
  }
}

.menuHeading {
  /* Font */
  @apply --marketplaceH2FontStyles;
  font-family: var(--fontFamilySecondary);
  color: var(--matterColorDark);
  line-height: 1;
  text-transform: uppercase;
  white-space: nowrap;

  /* Layout */
  margin: 0 0 24px;
}

.menuHeadingReversed {
}

.menuHeadingLink {
  color: var(--matterColorDark);
  transition: color 0.15s ease-in-out;

  &:hover {
    color: var(--marketplaceColor);
  }
}

.menu {
}

.twoColumnsMenu {
  column-count: 2;
}

.listItem {
  /* Font */
  @apply --marketplaceSmallFontStyles;
  text-transform: none;
  white-space: nowrap;

  /* Layout */
  margin-bottom: 7px;

  &:last-child {
    margin-bottom: 0;
  }
}

.listLink {
  position: relative;
  left: 0;

  /* Font */
  color: var(--matterColorDark);

  /* Animation */
  transition: all var(--transitionStyleButton);

  &:hover {
    left: 3px;
    color: var(--marketplaceColor);
  }
}
