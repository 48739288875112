@import '../../styles/propertySets.css';

:root {
  --arrowHoverTransition: 150ms cubic-bezier(0.215, 0.61, 0.355, 1);
  --arrowHoverOffset: translateX(3px);
  --hoverTransition: 0.15s ease-in-out;
}

.root {
  position: relative;
  top: 1px;
  margin-left: 8px;
  stroke-width: 2;
  fill: none;
  stroke: var(--marketplaceColor);

  &:hover {
    & .linePath {
      opacity: 1;
    }

    & .tipPath {
      transform: var(--arrowHoverOffset);
    }
  }
}

.linePath {
  opacity: 0;
  -webkit-transition: opacity var(--hoverTransition, var(--arrowHoverTransition));
  transition: opacity var(--hoverTransition), var(--arrowHoverTransition);
}

.tipPath {
  -webkit-transition: -webkit-transform var(--hoverTransition), var(--arrowHoverTransition);
  transition: -webkit-transform var(--hoverTransition), var(--arrowHoverTransition);
  transition: transform var(--hoverTransition), var(--arrowHoverTransition);
  transition: transform var(--hoverTransition), var(--arrowHoverTransition),
    -webkit-transform var(--hoverTransition), var(--arrowHoverTransition);
}
