@import './customMediaQueries.css';

.mainContentWrapper {
  padding: 50px 24px;
  background-color: var(--colorWhite);
  & .fixedWidthContainer {
    max-width: 760px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }
  & .whatsNewSec {
    display: flex;
    align-items: center;
    margin: 0px;
    float: left;
    width: 100%;
    & .leftSec {
      width: 50%;
      & > h1 {
        color: var(--colorBlack);
        font-size: 35px;
        line-height: 130%;
        font-weight: var(--fontWeightSemiBold);
        margin: 0;
        padding: 0;
        @media (--viewportSmall) {
          font-size: 50px;
          line-height: 140%;
        }
      }
      & > a {
        color: var(--colorGrey500);
        font-weight: var(--fontWeightRegular);
        line-height: 120%;
        font-size: 15px;
        padding: 0;
        &:hover {
          color: var(--marketplaceColor);
          text-decoration: underline;
        }
      }
    }
    & .rightSec {
      width: 50%;
      text-align: right;
      & > img {
        max-width: 100%;
        width: 200px;
      }
    }
  }
  & .notionSection {
    margin: 30px 0;
    float: left;
    width: 100%;
    padding-bottom: 20px;
    border-bottom: solid 1px var(--colorGrey100);

    @media (--viewportSmall) {
      padding-bottom: 60px;
      margin: 50px 0;
    }
    & > span {
      color: var(--colorGrey500);
      font-weight: var(--fontWeightRegular);
      line-height: 140%;
      font-size: 15px;
      margin: 0;
      padding: 0;
    }
    & > p {
      color: var(--colorGrey800);
      font-weight: var(--fontWeightRegular);
      line-height: 140%;
      font-size: 15px;
      margin: 0 0 20px 0;
      padding: 0;
    }
    & > h1 {
      color: var(--colorBlack);
      font-size: 30px;
      line-height: 130%;
      font-weight: var(--fontWeightSemiBold);
      margin: 0 0 20px 0;
      padding: 0;
      @media (--viewportSmall) {
        font-size: 40px;
        line-height: 140%;
      }
    }
    & > h2 {
      color: var(--colorBlack);
      font-size: 22px;
      line-height: 130%;
      font-weight: var(--fontWeightSemiBold);
      margin: 0 0 20px 0;
      padding: 0;
      @media (--viewportSmall) {
        font-size: 28px;
        line-height: 140%;
      }
    }
    & > img {
      max-width: 100%;
      margin: 20px auto;
    }
    & > video {
      max-width: 100%;
      margin: 20px auto;
    }
    & > ul {
      padding-left: 30px;
      list-style-type: disc;
      margin: 0 0 20px 0;
      & > li {
        color: var(--colorGrey800);
        font-weight: var(--fontWeightRegular);
        line-height: 140%;
        font-size: 15px;
        margin: 0 0 10px 0;
        padding: 0;
        &:last-child {
          margin-bottom: 0;
        }
        & > a {
          text-decoration: underline;
          color: var(--colorBlack);
          &:hover {
            color: var(--marketplaceColor);
          }
        }
      }
    }
    & .shareRelease {
      margin-bottom: 20px;
      float: left;
      width: 100%;
      & > span {
        color: var(--colorGrey500);
        font-weight: var(--fontWeightRegular);
        line-height: 140%;
        font-size: 12px;
        margin: 0 0 10px 0;
        text-transform: uppercase;
        padding: 0;
        width: 100%;
      }
      & .socailMedia {
        display: flex;
        align-items: center;
        margin: 10px 0;
        & > * {
          margin-right: 10px;
          &:last-child {
            margin-right: 0;
          }
        }
        & svg {
          width: 16px;
          height: 16px;
          & path {
            fill: var(--colorGrey600);
          }
        }
      }
    }
  }
  & .joinUsSection {
    margin: 30px 0;
    float: left;
    width: 100%;
    padding-bottom: 40px;
    border-bottom: solid 1px var(--colorGrey100);
    display: flex;
    align-items: center;

    @media (--viewportSmall) {
      padding-bottom: 60px;
      margin: 50px 0;
    }
    & .joinImg {
      & > img {
        max-width: 100%;
      }
    }
    & .joinNow {
      padding-left: 25px;
      width: calc(100% - 50px);
      & > h2 {
        color: var(--colorBlack);
        font-size: 18px;
        line-height: 120%;
        font-weight: var(--fontWeightSemiBold);
        margin: 0 0 8px 0;
        padding: 0;
      }
      & > p {
        color: var(--colorGrey400);
        font-weight: var(--fontWeightRegular);
        line-height: 140%;
        font-size: 14px;
        margin: 0 0 5px 0;
        padding: 0;
      }
      & > a {
        color: var(--colorBlack);
        font-weight: var(--fontWeightRegular);
        line-height: 120%;
        font-size: 14px;
        padding: 0;
        text-decoration: underline;
        &:hover {
          color: var(--marketplaceColor);
          text-decoration: underline;
        }
      }
    }
  }
  & .previousReleaseSection {
    margin: 30px 0;
    float: left;
    width: 100%;
    @media (--viewportSmall) {
      margin: 50px 0;
    }
    & > h1 {
      color: var(--colorBlack);
      font-size: 30px;
      line-height: 130%;
      font-weight: var(--fontWeightSemiBold);
      margin: 0 0 20px 0;
      padding: 0;
      @media (--viewportSmall) {
        margin: 0 0 40px 0;
        font-size: 40px;
        line-height: 140%;
      }
    }
    & .releaseBlock {
      display: flex;
      align-items: center;
      margin-bottom: 50px;
      flex-direction: column;
      @media (--viewportSmall) {
        flex-direction: row;
        margin-bottom: 30px;
      }
      & .releaseImg {
        position: relative;
        overflow: hidden;
        border-radius: 10px;
        box-shadow: 0px 0px 9px #0000002b;
        height: 150px;
        width: 100%;

        @media (--viewportSmall) {
          width: 300px;
        }
        & > img {
          max-width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      & .releaseInfo {
        width: 100%;
        padding-top: 20px;
        @media (--viewportSmall) {
          width: calc(100% - 300px);
          padding-left: 20px;
          padding-top: 0;
        }
        & > span {
          color: var(--colorGrey500);
          font-weight: var(--fontWeightRegular);
          line-height: 120%;
          font-size: 13px;
          margin: 0 0 10px 0;
          display: block;
          padding: 0;
          text-transform: uppercase;
        }
        & > h2 {
          color: var(--colorBlack);
          font-size: 20px;
          line-height: 120%;
          font-weight: var(--fontWeightSemiBold);
          margin: 0 0 15px 0;
          padding: 0;

          & > a {
            color: var(--colorBlack);
            &:hover {
              color: var(--marketplaceColor);
            }
          }
        }
        & > p {
          color: var(--colorGrey800);
          font-weight: var(--fontWeightRegular);
          line-height: 120%;
          font-size: 14px;
          margin: 0;
          padding: 0;
        }
      }
    }
    & .nextpage {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 20px;
      & > a {
        display: inline-block;
        padding: 10px 20px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        color: var(--colorGrey800);
        font-weight: var(--fontWeightSemiBold);
        text-decoration: none;
        font-size: 14px;
        line-height: 100%;
        border-radius: 5px;
        &:hover {
          background-color: var(--marketplaceColor);
          border: 1px solid var(--marketplaceColor);
          color: var(--colorWhite);
        }
        & .arrow {
          font-size: 20px;
          margin-left: 8px;
        }
      }
    }
  }
}
