@import '../../styles/propertySets.css';

.root {
}

.sectionContent {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;

  /* Font markup */
  text-align: center;

  @media (--viewportMedium) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 48px;
  }
}

.title {
  @apply --marketplaceH1FontStyles;

  margin-top: 0;
  margin-bottom: 0;
}

.message {
  color: var(--matterColorDark);
  max-width: 365px;
  margin: 24px auto 0;
  line-height: 24px;
}

.buttonContainer {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: center;

  /* Position */
  width: 100%;
  margin-top: 48px;
}

.actionButton {
  @apply --marketplaceButtonStyles;

  @media (--viewportMedium) {
    width: 260px;
  }
}

.content,
.socials,
.image {
  display: flex;
  align-items: center;
}

.image {
  position: relative;
  overflow: hidden;
  padding-bottom: 35%;

  @media (--viewportMedium) {
    padding-bottom: 50%;
  }

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;

    @media (--viewportMedium) {
      justify-content: flex-end;
    }
  }
}

.content {
  justify-content: center;
  flex-direction: column;
}

.socials {
  justify-content: center;

  @media (--viewportMedium) {
    justify-content: flex-start;
  }
}

.foxImage {
  height: 100%;
  border-bottom-left-radius: 13%;
  border-bottom-right-radius: 13%;
}

.icon {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: center;

  /* Style */
  background-color: var(--marketplaceColor);
  border-radius: 50%;
  transition: all var(--transitionStyleButton);

  /* Size */
  width: 54px;
  height: 54px;

  &:hover {
    background-color: var(--marketplaceColorDark);
    box-shadow: var(--boxShadowButton);
  }
}

.fbIcon {
  margin-right: 12px;
}

.svgIcon {
  height: 24px;
  width: 24px;
  fill: var(--matterColorLight);

  &:hover {
    fill: var(--matterColorLight);
  }
}
