.root {
  /* Expand to available space */
  flex-grow: 1;

  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    padding-top: 1px;
  }
}

.field {
  margin-bottom: 24px;
}

.error {
  color: var(--failColor);
}

.submitButton {
  margin-top: 48px;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
  }
}

.faqLink {
  text-decoration: underline;
  text-underline-position: under;
}

.textInputs {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  & .reviewContent {
    margin-bottom: 20px;
    width: 100%;
    @media (--viewportSmall) {
      margin-right: 20px;
      width: calc(100% / 2 - 10px);
    }
    &:nth-child(2n) {
      margin-right: 0;
    }
  }
}
