@import '../../styles/propertySets.css';

.root {
  display: flex;
  align-items: center;
  position: relative;

  background-color: #df492a;
  padding: 16px;

  @media (--viewportMedium) {
    padding: 10px 20px 9px;
  }
}

.content {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 12px;

  @media (--viewportMedium) {
    align-items: center;
    justify-content: center;
    flex-direction: row;

    width: 100%;
  }
}

.text {
  margin: 0 24px 0 0;
  display: inline-block;
  color: #fff;
  font-size: 16px;
  line-height: 20px;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.button {
  @apply --marketplaceButtonStyles;

  background: #2a3d4b;
  margin: 0;
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 14px;
  color: #fff;
  border: 0;

  max-width: 210px;
  min-height: 48px;
  white-space: nowrap;
  text-transform: capitalize;

  @media (--viewportMedium) {
    margin: 0 0 0 16px;
  }

  &:hover {
    text-decoration: none;
    background: #364f61;
  }
}

.closeContainer {
  display: flex;
  align-items: center;
  cursor: pointer;

  /* Position */
  position: absolute;
  right: 16px;

  opacity: 0.8;
  transition: all var(--transitionStyleButton);

  @media (--viewportMedium) {
    right: 20px;
  }

  &:hover {
    opacity: 1;
  }
}

.closeContainerIcon {
  fill: var(--matterColorLight);
  stroke: var(--matterColorLight);
}
