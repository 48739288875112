@import '../../styles/propertySets.css';

.root {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--matterColorLight);
  border-radius: 50%;

  /* Size */
  height: 26px;
  width: 26px;

  /* Position */
  position: absolute;
  top: 10px;
  right: 10px;

  &:hover {
    & .addToWishListIcon {
      fill: var(--heartIconColor);
      stroke: var(--heartIconColor);
    }
  }
}

.addToWishListIconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
}

.addToWishListSpinnerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  width: 16px;
}

.addToWishListIcon,
.addToWishListSpinnerIcon {
  height: 100%;
  width: 100%;
}

.activeWishListIcon {
  fill: var(--heartIconColor);
  stroke: var(--heartIconColor);
}
