@import '../../styles/propertySets.css';

.root {
  @apply --marketplaceModalBaseStyles;
  padding-top: 70px;

  @media (--viewportMedium) {
    flex-basis: 567px;
  }
}

.modalContent {
  flex-grow: 1;
  padding-top: 48px;

  @media (--viewportMedium) {
    padding-top: 24px;
  }
}

/* Icon of the modal */
.modalIcon {
  @apply --marketplaceModalIconStyles;
}

/* Title of the modal */
.modalTitle {
  @apply --marketplaceModalTitleStyles;
}

.reviewee {
  white-space: nowrap;
}

/* Paragraph for the Modal */
.modalMessage {
  @apply --marketplaceModalParagraphStyles;
  margin: 18px 0 24px;
}

.returnLinkContainer {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 12px;
  }
}

.returnLink {
  @apply --marketplaceSmallFontStyles;
  font-size: 14px;
  line-height: 1;
  margin: 0;

  display: flex;
  align-self: center;
}

.returnLinkIcon {
  fill: var(--marketplaceColor);
  stroke: transparent;

  width: 8px;
  margin-right: 8px;
}
