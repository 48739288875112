@import '../../styles/propertySets.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.priceInput {
  flex-shrink: 0;
  width: 100%;
  margin-bottom: 36px;
}

.submitButton {
  margin-top: 48px;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
  }
}

.payout {
  /* Font */
  @apply --marketplaceSmallFontStyles;

  /* Style */
  background-color: var(--matterColorBright);
  border: 1px solid var(--matterColorNegative);
  border-left: 5px solid var(--marketplaceColor);
  border-radius: 5px;

  /* Position and size */
  padding: 12px;
  margin: -12px 0 32px;
}

.delivery {
  margin-bottom: 24px;
}

.textInputs {
  display: flex;
  gap: 22px;
}
