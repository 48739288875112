@import '../../styles/propertySets.css';

.root {
  & label,
  & span {
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
    height: auto;
  }

  & label {
    & > span {
      &:first-child {
        position: relative;
        bottom: 1px;
      }
    }
  }
}

.methodsWrapper {
  margin-bottom: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid var(--matterColorNegative);

  @media (--viewportLarge) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  & > span {
    & label {
      margin-bottom: 12px;

      @media (--viewportLarge) {
        margin-bottom: 0;
      }
    }

    &:last-child {
      & label {
        margin-bottom: 0;
      }
    }
  }
}

.transactionMethodsWrapper {
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;

  & > span {
    &:first-child {
      margin-bottom: 6px;
    }
  }
}

.submitButton {
  background-color: var(--marketplaceColor);

  &:hover {
    background-color: var(--marketplaceColorDark);

    &:disabled {
      background-color: var(--matterColorNegative);
    }
  }
}

.changes {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
