@import '../../styles/propertySets.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;
  position: relative;
}

.listingCardLink {
  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;
  overflow: hidden;
  transition: var(--transitionStyleButton);
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  padding-bottom: 124%; /* Portrait */
  background: var(--matterColorNegative); /* Loading BG color */

  &:hover {
    & .rootForImage {
      transform: scale(1.1);
    }
  }
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  border-radius: var(--borderRadius);
  transition: all 0.35s ease-in-out;
}

.info {
  /* Layout */
  display: flex;
  flex-direction: row;
  padding: 14px 0 0;
}

.price {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--marketplaceColor);
  line-height: 1;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.title {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorDark);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.bottomInfo {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorDark);

  /* Layout */
  display: flex;
  align-items: center;
  justify-content: space-between;

  /* Margins */
  margin-top: 6px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 12px;
    margin-bottom: 0;
  }
}

.authorInfo {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorDark);

  /* Layout */
  display: flex;
  align-items: center;
  justify-content: space-between;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.avatar {
  height: 29px;
  width: 29px;
  margin-right: 12px;

  & span {
    font-size: 12px;
  }
}

.addToWishListContainer {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--matterColorLight);
  border-radius: 50%;

  /* Size */
  height: 26px;
  width: 26px;

  /* Position */
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 8;
}

.addToWishListIconContainer,
.addToWishListSpinnerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  width: 16px;
}

.addToWishListIcon,
.addToWishListSpinnerIcon {
  height: 100%;
  width: 100%;
}

.addToWishListContainer {
  &:hover {
    & .addToWishListIcon {
      fill: var(--failColor);
      stroke: var(--failColor);
    }
  }
}

.activeWishListIcon {
  fill: var(--failColor);
  stroke: var(--failColor);
}

.soldOutBanner {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: center;

  /* Positon */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  /* Size */
  padding: 15px;
  width: 100%;

  /* Styles */
  background: rgba(0, 0, 0, 0.7);

  /* Font */
  text-align: center;
}

.soldOutHeading {
  /* Font */
  font-size: 22px;
  line-height: 1;
  color: var(--matterColorLight);

  margin: 0;
}
