@import '../../styles/propertySets.css';

.root {
  & p {
    @apply --marketplaceSmallFontStyles;
    margin-top: 12px;
    margin-bottom: 12px;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  & ul {
    margin: 0;
    list-style: initial;
    padding-left: 48px;

    & li {
      @apply --marketplaceSmallFontStyles;
      font-size: 14px;
    }
  }
}
