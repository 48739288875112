@import '../../styles/propertySets.css';

.root {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.userName {
  /* Font */
  @apply --marketplaceH2FontStyles;
  font-size: 18px;

  /* Layout */
  margin: 24px 0 0;

  /*   
    Add dot at the end of each username
  */
  &:after {
    content: '.';
  }
}
