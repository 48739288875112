@import '../../styles/propertySets.css';

.root {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 12px;
  margin-top: 48px;

  @media (--viewportMedium) {
    margin-top: 0;
  }
}

.gridItem {
  /* Layout */
  position: relative;
  overflow: hidden;
  border-radius: 2px;

  /* Size */
  width: 100%;
  padding-bottom: 100%;

  /* On hover activate the overlay */
  &:hover {
    & .overlay {
      opacity: 1;
    }
  }
}

.gridImage {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: top;
}

.gridMessage {
  /* Layout and position */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  z-index: 3;

  /* Font */
  @apply --marketplaceMessageFontStyles;
  white-space: nowrap;
  text-shadow: 0 0 7px var(--matterColorDark);
  color: var(--matterColorLight);

  @media (--viewportMedium) {
    top: unset;
    bottom: 12px;
    left: 12px;
    transform: unset;
    text-shadow: 0 0 5px var(--matterColorDark);
  }
}

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.2);
  cursor: pointer;
  z-index: 2;

  /* Animation */
  transition: opacity 0.3s ease-in-out;
}

.shadow {
  position: absolute;
  bottom: -70px;
  left: 0;
  display: none;
  width: 100%;
  height: 0;
  box-shadow: 0 0 149px 90px rgb(0 0 0 / 30%);
  z-index: 1;

  /* Animation */
  transform: rotate(-24deg);

  @media (--viewportMedium) {
    display: block;
  }
}
