@import '../../styles/propertySets.css';

.root {
  display: flex;
  flex-direction: column;

  /* Layout: size and positioning */
  width: 100%;
  height: auto;
  margin-top: 24px;
}

.formWrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 24px;
}

.error {
  color: var(--failColor);
}

.errorPlaceholder {
  @media (--viewportMedium) {
    width: 100%;
    height: 32px;
  }
}

.field {
}

.name {
  margin-bottom: 24px;
}

@import '../../styles/customMediaQueries.css';
@import '../../styles/marketplaceDefaults.css';

.root {
  display: flex;
  flex-direction: column;

  /* Layout: size and positioning */
  width: 100%;
  height: auto;
  margin-top: 14px;
}

.error {
  color: var(--failColor);
}

.errorPlaceholder {
  @media (--viewportMedium) {
    width: 100%;
    height: 32px;
  }
}

.reviewRating {
  margin-bottom: 18px;

  @media (--viewportMedium) {
    margin-bottom: 16px;
  }
}

.reviewContent {
  flex-shrink: 0;
  margin-top: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-top: 30px;
  }
}

.submitButton {
  margin-top: auto;
  margin-bottom: 96px;
  flex-shrink: 0;

  @media (--viewportMedium) {
    display: inline-block;
    width: 100%;
    margin-top: 36px;
    margin-bottom: 0;
  }
}

.cardCarrier {
  display: flex;
  align-items: center;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 10px 30px;
  justify-content: space-between;
  border: 1px solid #0000;
  cursor: pointer;

  @media (max-width: 600px) {
    flex-wrap: wrap;
    gap: 20px;
    padding: 10px;
  }

  & .providerImage {
    & img {
      margin-right: 20px;
      width: 100px;
    }
  }
}

.selectedCard {
  border: 1px solid var(--marketplaceColorDark);
  background: var(--matterColorBright);
}

.carrierHeading {
  font-size: 14px;
  color: var(--matterColorDark);
  margin-bottom: 16px;
  font-weight: 600;
}

.cardCarrierData {
  flex-direction: column;
  display: flex;

  & .providerName {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }
}

.badgeBox {
  & span {
    background-color: var(--marketplaceColorLight);
    color: var(--matterColorLight);
    margin-left: 10px;
    border-radius: 3px;
    padding: 5px 10px;
    font-size: 9px;
    position: relative;
    bottom: 3px;

    @media (max-width: 768px) {
      margin-right: 10px;
      margin-left: 6px;
    }
  }
}

.selectIcon {
  cursor: pointer;
}

.estDeliveryText {
  color: var(--matterColorAnti);
  font-size: 12px;
  line-height: 15px;
}

.priceText {
  font-weight: 600;
  color: var(--marketplaceColor);
}

.title {
  display: flex;
}

.textInputs {
  @media (min-width: 1024px) {
    display: flex;
    justify-content: space-between;
  }
}
.carriersContainer {
  margin-bottom: 20px;
  margin-top: 16px;
  border-radius: 10px;
  width: 100%;
  & .cardCarrier {
    display: flex;
    align-items: center;
    border-radius: 5px;
    padding: 10px 20px;
    justify-content: space-between;
    cursor: pointer;
    border: solid 1px var(--matterColorNegative);
    &.selectedCard {
      border: 1px solid var(--marketplaceColorDark);
      background: var(--matterColorBright);
    }

    & .cardBox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media (max-width: 380px) {
        display: block;
      }
      & .providerImage {
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        & img {
          max-width: 100%;
          object-fit: contain;
        }
      }
      & .cardCarrierData {
        flex-direction: column;
        display: flex;
        width: calc(100% - 60px);
        padding-left: 10px;
        padding-right: 10px;
        @media (max-width: 380px) {
          position: relative;
          right: 15px;
          margin-top: 5px;
        }
        & .title {
          margin: 0;
          display: flex;
          align-items: center;
        }
        & .providerName {
          font-weight: 700;
          font-size: 15px;
          line-height: 120%;
          @media screen and (min-width: 325px) and (max-width: 1024px) {
            font-weight: 700;
            font-size: 10px;
            line-height: 120%;
          }
        }
      }
    }
    & .rightBox {
      display: flex;
      align-items: center;
      & > img {
        width: 28px;
      }
      & .priceBox {
        margin-right: 10px;
      }
    }
  }
}
.loader {
  display: flex;
  align-items: center;
  & > svg {
    margin-right: 10px;
    width: 20px;
    height: 20px;
  }
  & > p {
    margin: 0;
  }
}
.otherShippingMethod {
  margin-top: 30px;
  & .modalTitle {
    @apply --marketplaceModalTitleStyles;
    font-size: 20px;
  }
}

.note {
  margin: 0px 0px 10px 0;
  font-size: 13px;
  font-weight: 600;
  color: var(--marketplaceColor);
}

.relayImage {
  & > img {
    height: 65px;
    border-radius: 8px;
  }
}

.relayCarriers {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
}

.searchInput {
}

.relaySpinner {
  display: flex;
  justify-content: center;
}

.actionButtons {
  display: flex;
  justify-content: space-around;
  & > button {
    width: 45%;
    font-size: 13px;
  }
}

.toggleSection {
  display: flex;
  justify-content: space-evenly;
  background: #a95508;
  color: #fff;
  padding: 0px 17px;
  width: fit-content;
  margin-left: 36%;
  border-radius: 23px;
  cursor: pointer;
  margin-bottom: 10px;
}

.singleRelayCarrier {
  width: 80% !important;
}
