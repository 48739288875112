@import '../../styles/propertySets.css';

.root {
  display: flex;
  justify-content: center;
}

.sectionContent {
  display: flex;
  align-content: center;
  flex-direction: column-reverse;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.title {
  @apply --marketplaceH1FontStyles;

  margin-top: 0;
  margin-bottom: 0;
}

.message {
  color: var(--matterColorDark);
  max-width: 365px;
  margin: 21px auto 17px;
  line-height: 24px;
}

.content {
  align-self: center;
  text-align: center;
}

.image {
  display: flex;
  justify-content: center;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-left: 72px;
    margin-bottom: 0;
  }
}

.foxImage {
  height: 120px;
  border-bottom-left-radius: 13%;
  border-bottom-right-radius: 13%;

  @media (--viewportMedium) {
    height: 175px;
  }
}

.paymentIcons {
}

.icon {
  height: 60px;
  width: 60px;
  margin-right: 12px;

  &:last-child {
    margin-right: 0;
  }
}
