@import '../../styles/propertySets.css';

.root {
  display: flex;
  flex-direction: column;
}

.card {
  @apply --marketplaceInputStyles;

  /* Layout */
  padding: 8px 0 6px 0;
  height: 36px;

  /* Border */
  border-bottom-color: var(--attentionColor);

  @media (--viewportMedium) {
    height: 38px;
  }
  @media (--viewportLarge) {
    height: 38px;
    padding: 6px 0 14px 0;
  }
}

.cardSuccess {
  border-bottom-color: var(--successColor);
}

.cardError {
  border-bottom-color: var(--failColor);
}

.error {
  color: var(--failColor);
}

.errorMessage {
  margin-top: 24px;
  color: var(--failColor);
}

.paymentHeading {
  margin: 0 0 12px 0;
  color: var(--matterColorAnti);

  padding-top: 4px;
  padding-bottom: 2px;

  @media (--viewportMedium) {
    margin: 0 0 16px 0;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.paymentMethodSelector {
  margin-bottom: 36px;

  @media (--viewportMedium) {
    margin-bottom: 40px;
  }
}

.saveForLaterUse {
  padding-top: 6px;
  margin-bottom: 36px;

  @media (--viewportMedium) {
    padding-top: 8px;
    margin-top: 0px;
    margin-bottom: 48px;
  }
}

.saveForLaterUseCheckbox {
  display: inline-block;
  padding: 2px 0 3px 0;

  @media (--viewportMedium) {
    padding: 1px 0 0px 0;
  }
}

.saveForLaterUseLabel {
  @apply --marketplaceH5FontStyles;
  color: var(--matterColor);
  margin-top: 3px;
  margin-bottom: 0px;
  cursor: pointer;
}

.saveForLaterUseLegalInfo {
  @apply --marketplaceH5FontStyles;
  color: var(--matterColorAnti);
  display: inline-block;
  padding: 0px 0 0px 26px;
  margin-top: -2px;
  margin-bottom: 2px;

  @media (--viewportMedium) {
    margin-top: -1px;
    margin-bottom: 1px;
  }
}

.billingHeading {
  margin: 0 0 12px 0;
  color: var(--matterColorAnti);

  padding-top: 4px;
  padding-bottom: 2px;

  @media (--viewportMedium) {
    margin: 0 0 24px 0;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.paymentLabel {
  margin: 0;

  @media (--viewportMedium) {
    margin-top: 0px;
    margin-bottom: 2px;
  }
}

.messageHeading {
  color: var(--matterColorAnti);
  margin: 36px 0 12px 0;

  padding-top: 4px;
  padding-bottom: 2px;

  @media (--viewportMedium) {
    margin: 24px 0 24px 0;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.messageLabel {
  margin: 0 0 5px 0;

  @media (--viewportMedium) {
    margin: 0 0 6px 0;
  }
}

.messageOptional {
  color: var(--matterColorAnti);
}

.message {
  border-bottom-color: var(--matterColorAnti);
}

.submitContainer {
  margin-top: auto;

  @media (--viewportLarge) {
    margin-top: 72px;
  }
}

.paymentInfo {
  font-size: 15px;
  color: #8e8e8e;
  text-align: center;
  padding: 0 42px;
  margin: 28px 0 0 0;

  @media (--viewportLarge) {
    padding: 0;
  }
}

.submitButton {
  margin-top: 22px;

  @media (--viewportMedium) {
    margin-top: 26px;
  }
  @media (--viewportLarge) {
    margin-top: 17px;
  }
}

.missingStripeKey {
  color: var(--failColor);
}
.messageContainer {
  & > h3 {
    margin-top: 0;
    padding: 0;
  }
}
.selectedShippingMethod {
  width: 100%;
  margin: 30px 0;
  & > h2 {
    font-weight: 600;
    font-size: 16px;
    color: #b6b6b6;
    margin-top: 0;
    margin-bottom: 10px;
  }
  & .shippingMethodInfo {
    border: solid 1px var(--matterColorNegative);
    border-radius: 5px;
    padding: 15px;
    box-shadow: 0 3px 6px 0px rgb(169 162 162 / 81%);
    max-width: 280px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    & .shippingBy {
      display: flex;
      align-items: center;
      margin-bottom: 5px;
      & > img {
        width: 50px;
        margin-right: 10px;
      }
      & > span {
        font-weight: var(--fontWeightBold);
        font-size: 13px;
        color: var(--marketplaceColor);
      }
    }
    & .additonalInfo {
      margin: 0;
      color: var(--matterColor);
      line-height: 140%;
      font-size: 14px;
      font-weight: 600;
    }
  }
}

.editShippingMethod {
  font-size: 13px;
  position: relative;
  bottom: 10px;
  left: 6px;
  cursor: pointer;
  & > img {
    width: 20px;
  }
}
