.root {
  fill: var(--matterColorDark);

  /* Fill animation */
  transition: all 0.25s ease-in-out;
}

.isFilled {
  fill: var(--marketplaceColor);
  stroke: var(--marketplaceColor);
}
